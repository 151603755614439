import React, { useState } from 'react';
import { TFunction } from 'i18next';
// import { LocaleNameSpaceKeys } from '../../localization/translations';
import * as EmailValidator from 'email-validator';

import Input, { InputType } from '../primitives/Input/Input';
import Button from '../primitives/Button/Button';

interface OwnProps {
  t: TFunction;
  bsvCode: string;
  setCurrentStep: (step: number) => void;
  setGenericError: (val: boolean) => void;
}

enum FormDataKey {
  phone = 'phone',
  email = 'email',
}

type Props = OwnProps;

const InstantVerifcationForm: React.FC<Props> = ({
  t,
  bsvCode,
  setCurrentStep,
  setGenericError,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    phone: {
      value: '',
      validationMessage: '',
      isInvalid: false,
    },
    email: {
      value: '',
      validationMessage: '',
      isInvalid: false,
    },
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const newFormData = { ...formData };

    Object.keys(newFormData).forEach((element) => {
      const currentField = newFormData[element as FormDataKey];

      if (!currentField.value) {
        currentField.isInvalid = true;

        currentField.validationMessage =
          element === 'email'
            ? t('instantVerification.form.email.validationMessage.emptyField')
            : t('instantVerification.form.phone.validationMessage.emptyField');
      } else if (element === 'email' && !EmailValidator.validate(currentField.value)) {
        currentField.isInvalid = true;

        currentField.validationMessage = t(
          'instantVerification.form.email.validationMessage.invalidCharacters'
        );
      } else if (element === 'phone' && currentField.value.length !== 10) {
        currentField.isInvalid = true;

        currentField.validationMessage = t(
          'instantVerification.form.phone.validationMessage.invalidCharacters'
        );
      } else {
        currentField.isInvalid = false;
        currentField.validationMessage = '';
      }
    });

    setFormData(newFormData);

    if (!newFormData.email.isInvalid && !newFormData.phone.isInvalid) {
      setIsSubmitting(true);

      fetch(`${process.env.GATSBY_INSTANT_VERIFICATION_API_URL}/contactInfo`, {
        method: 'POST',
        // @ts-ignore
        headers: {
          'Content-Type': 'application/json',
          client_id: process.env.GATSBY_LENDING_AREAS_CLIENT_ID,
          client_secret: process.env.GATSBY_LENDING_AREAS_CLIENT_SECRET,
        },
        body: JSON.stringify({
          bsvCode,
          email: newFormData.email.value,
          phone: newFormData.phone.value,
        }),
      })
        .then(() => {
          setIsSubmitting(false);
          setCurrentStep(3);
        })
        .catch(() => {
          setCurrentStep(0);
          setGenericError(true);
        });
    }
  };

  const handleFormChange = (val: any, id: FormDataKey) => {
    setFormData({
      ...formData,
      [id]: {
        ...formData[id],
        value: val,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit} className="InstantVerification__generic-gap" noValidate>
      <Input
        id="phone"
        isInvalid={formData.phone.isInvalid}
        validationMessage={formData.phone.validationMessage}
        options={{
          type: InputType.NUMBER,
          label: t('instantVerification.form.phone.label'),
          inputProps: {
            name: 'phone',
            value: formData.phone.value,
            min: 0,
            max: 9999999999,
          },
        }}
        onChange={handleFormChange}
      />
      <Input
        id="email"
        isInvalid={formData.email.isInvalid}
        validationMessage={formData.email.validationMessage}
        options={{
          type: InputType.EMAIL,
          label: t('instantVerification.form.email.label'),
          inputProps: {
            name: 'email',
            value: formData.email.value,
          },
        }}
        onChange={handleFormChange}
      />
      <p className="small">{t('instantVerification.form.phone.helper')}</p>
      <Button
        id="instant-verification-submit"
        type="submit"
        styleOptions={{ isInline: true }}
        disabled={isSubmitting}
      >
        {t('instantVerification.form.submitLabel')}
      </Button>
    </form>
  );
};

export default InstantVerifcationForm;
