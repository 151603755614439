import React, { useEffect, useState, useCallback } from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';
import qs from 'qs';

import { useLocaleContext } from '../../context/Locale';
import Layout, { LayoutOptions, HeroSectionColors } from '../../components/Layout/Layout';
import InstantVerificationForm from '../../components/InstantVerification/Form';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import RouteKeys from '../../../config/RouteKeys';
import { LocaleTag } from '../../../config/locales';
import './style.scss';

type Props = PageProps & WithTranslation;

const iFrameStepsMonitored: any = {
  COMPONENT_LOAD_INSTITUTION_SELECTOR: true,
  COMPONENT_LOAD_CREDENTIAL: true,
  COMPONENT_LOAD_ACCOUNT_SELECTION: true,
};

const InstantVerification: React.FC<Props> = (props) => {
  const { activeLocaleTag } = useLocaleContext();
  const { t, location } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [currentLocaleStep, setCurrentLocaleStep] = useState('');
  const [linkInvalid, setLinkInvalid] = useState(false);
  const [genericError, setGenericError] = useState(false);
  const [bsvCode, setBsvCode] = useState('');

  const onIFrameProgress = useCallback((event) => {
    if (event && event.data && event.data.step && iFrameStepsMonitored[event.data.step as string]) {
      setCurrentLocaleStep(event.data.step);
    }
  }, []);

  const reset = () => {
    setCurrentStep(0);
    setGenericError(false);
    setLinkInvalid(false);
    window.removeEventListener('message', onIFrameProgress);
  };

  useEffect(() => {
    const { id, skipVerification } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if (!id) {
      setLinkInvalid(true);
      return reset;
    }

    window.addEventListener('message', onIFrameProgress);

    if (skipVerification === 'true') {
      setCurrentStep(3);
    } else {
      setCurrentStep(1);
      fetch(`${process.env.GATSBY_INSTANT_VERIFICATION_API_URL}/bsvCode?bsvCode=${id}`, {
        method: 'GET',
        // @ts-ignore
        headers: {
          'Content-Type': 'application/json',
          client_id: process.env.GATSBY_LENDING_AREAS_CLIENT_ID,
          client_secret: process.env.GATSBY_LENDING_AREAS_CLIENT_SECRET,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.errorCode || !data.bsvCode) {
            setCurrentStep(0);
            setLinkInvalid(true);
          } else {
            setCurrentStep(2);
            setBsvCode(data.bsvCode);
            localStorage.setItem('bsvCode', data.bsvCode);
          }
        })
        .catch(() => {
          setGenericError(true);
          setCurrentStep(0);
        });
    }

    return reset;
  }, []);

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('instantVerification.seo.title'),
    },
    page: {
      title:
        currentStep === 3 && currentLocaleStep
          ? t(`instantVerification.iFrame.${currentLocaleStep}.title`)
          : t('instantVerification.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: 'hero-image-4.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const iFrameParams = [
    `language=${activeLocaleTag}`,
    'innerRedirect=false',
    'headerEnable=false',
    'institutionFilterEnable=true',
    'consentEnable=false',
    'features=AccountInfo,AccountNumber,TransactionHistory',
    `termsUrl=${location.origin}/${activeLocaleTag}${RouteKeys.TERMS_CONDITION.INDEX.path[activeLocaleTag]}`,
    'accountSelectorCurrency=cad,usd',
    'daysOfTransactions=Days365',
    'enhancedMFA=true',
    `redirectUrl=${location.origin}${RouteKeys.INSTANT_VERIFICATION.BANKING_INFORMATION.INDEX.path.en}`,
    'showAllOperationAccounts=true',
    activeLocaleTag === LocaleTag.EN
      ? 'customerName=Haventree Bank and for the purposes of qualifying for a mortgage. The Haventree Bank privacy statement can be found via the link at the bottom of this page'
      : 'customerName=la Banque Haventree pour déterminer votre admissibilité à un prêt hypothécaire. La déclaration de confidentialité de la Banque Haventree se trouve au bas de cette page par le lien',
  ];

  const getIFrameUrl = () => {
    return `${process.env.GATSBY_INSTANT_VERIFICATION_IFRAME_URL}&${iFrameParams.join('&')}`;
  };

  return (
    <Layout options={layoutOptions} className="InstantVerification">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-80">
            {currentStep === 1 ? <h4>{t('instantVerification.linkValidationMessage')}</h4> : null}
            {linkInvalid ? (
              <ErrorMessage>{t('instantVerification.linkValidationFailure')}</ErrorMessage>
            ) : null}
            {currentStep === 2 ? (
              <>
                <p>
                  <Trans t={t} i18nKey="instantVerification.linkValidationSuccess" />
                </p>
                <br />
                <InstantVerificationForm
                  t={t}
                  bsvCode={bsvCode}
                  setCurrentStep={setCurrentStep}
                  setGenericError={setGenericError}
                />
              </>
            ) : null}
            {genericError ? (
              <ErrorMessage>
                {t('instantVerification.form.submissionValidation.error')}
              </ErrorMessage>
            ) : null}
          </section>
          {currentStep === 3 ? (
            <section className="column column-100">
              {currentLocaleStep ? (
                <>
                  {/* <h4>{t(`instantVerification.iFrame.${currentLocaleStep}.title`)}</h4> */}
                  <p>
                    <Trans
                      t={t}
                      i18nKey={`instantVerification.iFrame.${currentLocaleStep}.description`}
                    />
                  </p>
                </>
              ) : null}
              <iframe
                frameBorder="none"
                title="banking-information"
                src={getIFrameUrl()}
                width="100%"
                height="510px"
              />
            </section>
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.INSTANT_VERIFICATION)(InstantVerification);
